import { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import "./article.scss";
import { ButtonDown } from "../../components/ButtonDown/ButtonDown";
import { ButtonLeft } from "../../components/ButtonLeft/ButtonLeft";
import useThrottle from "../../utils/hooks/useThrottle";

export const Article = forwardRef((props, ref) => {
  const { section } = props;
  const { content, media, subTitle, title } = section;

  const paragraphRef = useRef(null);

  const handleClick = () => {
    if (paragraphRef.current) {
      const yOffset = -100;
      const element = paragraphRef.current;
      const y = element.getBoundingClientRect().top + window.scrollY + yOffset;

      window.scrollTo({
        top: y,
        behavior: "smooth",
      });
    }
  };

  const goBack = () => {
    window.history.back();
  };

  const [isFixed, setIsFixed] = useState(false);
  const upperContentRef = useRef(null);
  const articleWrapperRef = useRef(null);

  const handleScroll = useCallback(() => {
    if (
      window.innerWidth <= 960 &&
      upperContentRef.current &&
      articleWrapperRef.current
    ) {
      const upperRect = upperContentRef.current.getBoundingClientRect();
      const wrapperRect = articleWrapperRef.current.getBoundingClientRect();
      const threshold = 5 * 13;

      if (upperRect.top <= threshold && wrapperRect.top <= threshold) {
        requestAnimationFrame(() => {
          paragraphRef.current.style.marginTop = `${upperRect.height}px`;
        });
        setIsFixed(true);
      } else {
        requestAnimationFrame(() => {
          paragraphRef.current.style.marginTop = "0";
        });
        setIsFixed(false);
      }
    } else {
      requestAnimationFrame(() => {
        paragraphRef.current.style.marginTop = "0";
      });
      setIsFixed(false);
    }
  }, []);

  const throttledHandleScroll = useThrottle(handleScroll, 10);

  useEffect(() => {
    handleScroll();
  }, [handleScroll]);

  useEffect(() => {
    window.addEventListener("scroll", throttledHandleScroll);
    return () => window.removeEventListener("scroll", throttledHandleScroll);
  }, [throttledHandleScroll]);

  return (
    <section className="article-section">
      <div className="article-content">
        <div className="article-image-container">
          <img
            src={media.data.path}
            alt={media.data.alt}
            className="article-image"
            draggable="false"
          />
        </div>

        <div ref={articleWrapperRef} className="article-content-wrapper">
          <div
            ref={upperContentRef}
            className={`article-content-upper ${isFixed ? "fixed" : ""}`}
          >
            <span className="article-pretitle">{subTitle.data}</span>
            <span className="article-title">{title.data}</span>
          </div>

          <div className="article-button-down">
            <ButtonDown color="dark" onClick={handleClick} />
          </div>

          <div className="article-content-lower">
            <div
              ref={paragraphRef}
              className="article-description"
              dangerouslySetInnerHTML={{
                __html: content.data,
              }}
            ></div>
          </div>
        </div>

        <div className="article-button">
          <ButtonLeft color="dark" onClick={goBack} />
        </div>
      </div>
    </section>
  );
});
