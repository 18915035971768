import { forwardRef, useContext, useEffect, useRef, useState } from "react";
import "./video.scss";
import { ButtonDown } from "../../components/ButtonDown/ButtonDown";
import { StoreContext } from "../../store/store";
import { Sound } from "../../components/Sound/Sound";

export const Video = forwardRef((props, ref) => {
  const { section } = props;
  const { listSliderProduction, optionSlider } = section;
  const { lang } = useContext(StoreContext);

  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselRef = useRef(null);

  const isSlider = optionSlider.data === "true";

  const content = isSlider
    ? listSliderProduction.data
    : listSliderProduction.data[0][lang];

  const videoRef = useRef(null);

  const handleClick = () => {
    window.scrollTo({
      top: videoRef.current.clientHeight,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (isSlider) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % content.length);
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [isSlider, content.length]);

  const handleManualSlide = (direction) => {
    setCurrentIndex((prevIndex) => {
      if (direction === "left") {
        return prevIndex === 0 ? content.length - 1 : prevIndex - 1;
      } else {
        return (prevIndex + 1) % content.length;
      }
    });
  };

  useEffect(() => {
    if (carouselRef.current) {
      carouselRef.current.style.transform = `translateX(-${
        currentIndex * 100
      }%)`;
    }
  }, [currentIndex]);

  return (
    <div className="video-section">
      {isSlider ? (
        <div className="video-section-carousel-container" ref={videoRef}>
          <div className="video-section-carousel-item" ref={carouselRef}>
            {content.map((image, index) => {
              const { from, media, title } = image[lang];

              return (
                <div
                  key={index}
                  style={{ minWidth: "100%", position: "relative" }}
                >
                  <img
                    src={media.data.path}
                    alt={media.data.alt}
                    className="video-section-carousel-item-image"
                    draggable="false"
                  />
                  <span className="video-text video-text-left">
                    {title.data}
                  </span>
                  <span className="video-text video-text-right">
                    {from.data}
                  </span>
                </div>
              );
            })}
          </div>

          <button
            className="carousel-arrow left"
            onClick={() => handleManualSlide("left")}
          >
            &#8249;
          </button>
          <button
            className="carousel-arrow right"
            onClick={() => handleManualSlide("right")}
          >
            &#8250;
          </button>

          <div className="video-button">
            <ButtonDown onClick={handleClick} />
          </div>
        </div>
      ) : (
        <div className="video-section-video-container">
          <div className="video-background-gradient" />

          <video
            ref={videoRef}
            src={content.media.data.path}
            alt={content.media.data.alt}
            autoPlay
            loop
            muted
            width="100%"
            draggable="false"
            playsInline
            className="video-section-video"
          />

          <span className="video-text video-text-left">
            {content.title.data}
          </span>
          <span className="video-text video-text-right">
            {content.from.data}
          </span>

          <div className="sound-container">
            <Sound videoRef={videoRef} />
          </div>

          <div className="video-button">
            <ButtonDown onClick={handleClick} />
          </div>
        </div>
      )}
    </div>
  );
});
