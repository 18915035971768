import { forwardRef, useContext, useEffect, useRef, useState } from "react";
import "./carousel.scss";
import { StoreContext } from "../../store/store";
import useIsMobile from "../../utils/hooks/useIsMobile";
import useSwipe from "../../utils/hooks/useSwipe";
import { useLocation } from "react-router-dom";

export const Carousel = forwardRef((props, ref) => {
  const { section } = props;
  const { listLogo, title } = section;
  const { lang } = useContext(StoreContext);
  const isMobile = useIsMobile();

  const location = useLocation();
  const isGroupPage =
    location.pathname.includes("le-groupe") ||
    location.pathname.includes("group");

  const containerRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  // Swiper

  const [indexChangeSource, setIndexChangeSource] = useState(null);

  const swipeHandlers = useSwipe({
    onSwipedLeft: () => handleNext(),
    onSwipedRight: () => handlePrev(),
  });

  const handlePrev = () => {
    setIndexChangeSource("click");
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? mobileGroups.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setIndexChangeSource("click");
    setCurrentIndex((prevIndex) =>
      prevIndex === mobileGroups.length - 1 ? 0 : prevIndex + 1
    );
  };

  useEffect(() => {
    if (indexChangeSource === "click" && containerRef.current) {
      const containerWidth = containerRef.current.getBoundingClientRect().width;
      const scrollPosition = currentIndex * containerWidth;

      containerRef.current.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      });

      setTimeout(() => {
        setIndexChangeSource(null);
      }, 500);
    }
  }, [currentIndex, indexChangeSource]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const handleScroll = () => {
        if (indexChangeSource !== "click") {
          const containerWidth = container.getBoundingClientRect().width;
          const scrollPosition = container.scrollLeft;
          const newIndex = Math.round(scrollPosition / containerWidth);

          if (newIndex !== currentIndex) {
            setCurrentIndex(newIndex);
          }
        }
      };

      container.addEventListener("scroll", handleScroll);
      return () => container.removeEventListener("scroll", handleScroll);
    }
  }, [currentIndex, indexChangeSource, isMobile]);

  // Calcul de la durée d'animation en fonction du nombre de logos
  const calculateAnimationDuration = (numberOfLogos) => {
    const baseSpeed = 30;
    return (numberOfLogos * baseSpeed) / 10;
  };

  const animationDuration = calculateAnimationDuration(listLogo.data.length);

  // Création des groupes pour mobile
  const mobileGroups = [];
  const nbMobileRows = 3;
  const nbPerMobileRow = 5;
  for (
    let i = 0;
    i < listLogo.data.length;
    i += nbMobileRows * nbPerMobileRow
  ) {
    const group = [];
    for (let j = 0; j < nbMobileRows; j++) {
      const rowStart = i + j * nbPerMobileRow;
      const row = listLogo.data.slice(rowStart, rowStart + nbPerMobileRow);
      if (row.length < nbPerMobileRow) {
        const remaining = nbPerMobileRow - row.length;
        group.push([...row, ...row.slice(0, remaining)]);
      } else {
        group.push(row);
      }
    }
    mobileGroups.push(group);
  }

  // Création des groupes pour desktop
  const desktopGroups = [];
  const nbRows = 3;
  const nbPerRows = listLogo.data.length / nbRows;

  if (isGroupPage) {
    for (let i = 0; i < listLogo.data.length; i += nbPerRows) {
      const group = listLogo.data.slice(i, i + nbPerRows);

      if (i + nbPerRows >= listLogo.data.length && group.length < nbPerRows) {
        const remaining = nbPerRows - group.length;
        const completedGroup = [...group, ...group.slice(0, remaining)];
        desktopGroups.push(completedGroup);
      } else {
        desktopGroups.push(group);
      }
    }
  } else {
    desktopGroups.push(listLogo.data);
  }

  const renderCompanies = (companies) =>
    [...companies, ...companies, ...companies, ...companies].map(
      (company, index) => (
        <div key={index} className="carousel-content-image-wrapper">
          <img
            src={company[lang].media.data.path}
            alt={company[lang].media.data.alt}
            key={index}
            className="carousel-content-image"
            draggable="false"
          />
        </div>
      )
    );

  return (
    <section className="carousel-section">
      {title.data && <span className="carousel-title">{title.data}</span>}

      <div className="carousel-content">
        {isMobile ? (
          <div className="carousel-mobile-wrapper">
            {mobileGroups.length > 1 && (
              <div className="carousel-mobile-scroll-left" onClick={handlePrev}>
                <img
                  src="/icon/chevron-left.svg"
                  alt="chevron-left"
                  draggable="false"
                />
              </div>
            )}
            <div
              {...swipeHandlers}
              ref={containerRef}
              className="carousel-mobile-container"
            >
              {mobileGroups.map((group, groupIndex) => (
                <div key={groupIndex} className="carousel-mobile-group">
                  {group.map((row, rowIndex) => (
                    <div key={rowIndex} className="carousel-mobile-row">
                      {row.map((company, index) => (
                        <img
                          src={company[lang].media.data.path}
                          alt={company[lang].media.data.alt}
                          key={index}
                          className="carousel-mobile-image"
                          draggable="false"
                        />
                      ))}
                    </div>
                  ))}
                </div>
              ))}
            </div>

            {mobileGroups.length > 1 && (
              <div
                className="carousel-mobile-scroll-right"
                onClick={handleNext}
              >
                <img
                  src="/icon/chevron-right.svg"
                  alt="chevron-right"
                  draggable="false"
                />
              </div>
            )}

            {mobileGroups.length > 1 && (
              <div className="carousel-bullets">
                {mobileGroups.map((_, index) => (
                  <div
                    key={index}
                    className={`carousel-bullet ${
                      index === currentIndex ? "active" : ""
                    }`}
                    onClick={() => {
                      setIndexChangeSource("click");
                      setCurrentIndex(index);
                    }}
                  />
                ))}
              </div>
            )}
          </div>
        ) : (
          desktopGroups.map((companies, index) => (
            <div className="carousel-content-row" key={index}>
              {Array.from({ length: 4 }).map((_, index) => (
                <div
                  key={index}
                  className="carousel-content-inner"
                  style={{
                    animation: `slideLeft ${animationDuration}s linear infinite`,
                  }}
                >
                  {renderCompanies(companies)}
                </div>
              ))}
            </div>
          ))
        )}
      </div>
    </section>
  );
});
