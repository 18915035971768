import {
  forwardRef,
  useContext,
  useEffect,
  useRef,
  useState,
  Fragment,
} from "react";
import "./ourChannelActivity.scss";
import { StoreContext } from "../../store/store";
import useSwipe from "../../utils/hooks/useSwipe";
import useIsMobile from "../../utils/hooks/useIsMobile";
import { Link } from "react-router-dom";

export const OurChannelActivity = forwardRef((props, ref) => {
  const { section } = props;
  const { title, listCountryChannel } = section;

  return (
    <div className="our-channel-activity-section">
      <div className="our-channel-activity-title">{title.data}</div>
      <div className="our-channel-activity-country-container">
        {listCountryChannel.data.length > 0 &&
          listCountryChannel.data.map((country, index) => (
            <Fragment key={index}>
              <CountryChannels country={country} />
            </Fragment>
          ))}
      </div>
    </div>
  );
});

const CountryChannels = ({ country }) => {
  const { lang } = useContext(StoreContext);
  const isMobile = useIsMobile();

  const { listChannel, nbLine, title } = country[lang];

  const lines = nbLine.data;
  const itemsPerLine = isMobile ? 2 : 4;
  const itemsPerSlide = lines * itemsPerLine;

  const carousel = [];
  for (let i = 0; i < listChannel.data.length; i += itemsPerSlide) {
    carousel.push(listChannel.data.slice(i, i + itemsPerSlide));
  }

  const [currentIndex, setCurrentIndex] = useState(0);
  const containerRef = useRef(null);

  // Swiper

  const [indexChangeSource, setIndexChangeSource] = useState(null);

  const swipeHandlers = useSwipe({
    onSwipedLeft: () => handleNext(),
    onSwipedRight: () => handlePrev(),
  });

  const handlePrev = () => {
    setIndexChangeSource("click");
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? carousel.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setIndexChangeSource("click");
    setCurrentIndex((prevIndex) =>
      prevIndex === carousel.length - 1 ? 0 : prevIndex + 1
    );
  };

  useEffect(() => {
    if (indexChangeSource === "click" && containerRef.current) {
      const containerWidth = containerRef.current.getBoundingClientRect().width;
      const scrollPosition = currentIndex * containerWidth;

      containerRef.current.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      });

      setTimeout(() => {
        setIndexChangeSource(null);
      }, 500);
    }
  }, [currentIndex, indexChangeSource]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const handleScroll = () => {
        if (indexChangeSource !== "click") {
          const containerWidth = container.getBoundingClientRect().width;
          const scrollPosition = container.scrollLeft;
          const newIndex = Math.round(scrollPosition / containerWidth);

          if (newIndex !== currentIndex) {
            setCurrentIndex(newIndex);
          }
        }
      };

      container.addEventListener("scroll", handleScroll);
      return () => container.removeEventListener("scroll", handleScroll);
    }
  }, [currentIndex, indexChangeSource]);

  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const dragThreshold = 5;

  const handleClick = (e) => {
    const deltaX = Math.abs(e.clientX - startX);
    if (isDragging && deltaX > dragThreshold) {
      e.preventDefault();
    }
  };

  const handleDragStart = (e) => {
    setStartX(e.clientX);
    setIsDragging(true);
  };

  const handleDragEnd = () => {
    setTimeout(() => {
      setIsDragging(false);
      setStartX(0);
    }, 0);
  };

  return (
    <div className="our-channel-activity-country">
      <div className="our-channel-activity-country-title">{title.data}</div>

      <div
        className="our-channel-activity-carousel-left-scroll"
        onClick={handlePrev}
      >
        <img
          src="/icon/chevron-left.svg"
          alt="chevron-left"
          draggable="false"
        />
      </div>

      <div
        {...swipeHandlers}
        ref={containerRef}
        className="our-channel-activity-carousel"
      >
        {carousel.map((slide, slideIndex) => (
          <div key={slideIndex} className="our-channel-activity-carousel-slide">
            <div
              className="our-channel-activity-carousel-slide-logo-grid"
              style={{
                gridTemplateRows: `repeat(${lines}, 1fr)`,
                gridTemplateColumns: `repeat(${itemsPerLine}, 1fr)`,
              }}
            >
              {slide.map((channel, index) => {
                const { media, btnUrl } = channel[lang];

                return (
                  <div
                    key={index}
                    className="our-channel-activity-carousel-slide-logo-item"
                  >
                    <Link
                      to={btnUrl.data}
                      target="_blank"
                      style={{ width: "100%" }}
                      onClick={handleClick}
                      onMouseDown={handleDragStart}
                      onMouseUp={handleDragEnd}
                      onMouseLeave={handleDragEnd}
                      draggable="false"
                    >
                      <img
                        src={media.data.path}
                        alt={media.data.alt}
                        className="our-channel-activity-carousel-slide-logo-item-img"
                        draggable="false"
                      />
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>

      <div
        className="our-channel-activity-carousel-right-scroll"
        onClick={handleNext}
      >
        <img
          src="/icon/chevron-right.svg"
          alt="chevron-right"
          draggable="false"
        />
      </div>

      <div className="our-channel-activity-bullets">
        {carousel.map((_, index) => (
          <div
            key={index}
            className={`our-channel-activity-bullet ${
              index === currentIndex ? "active" : ""
            }`}
            onClick={() => {
              setIndexChangeSource("click");
              setCurrentIndex(index);
            }}
          />
        ))}
      </div>

      <div className="our-channel-activity-border our-channel-activity-border-top-left" />
      <div className="our-channel-activity-border our-channel-activity-border-top-right" />
      <div className="our-channel-activity-border our-channel-activity-border-bottom-left" />
      <div className="our-channel-activity-border our-channel-activity-border-bottom-right" />
    </div>
  );
};
