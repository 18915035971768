import "./buttonActive.scss";

export const ButtonActive = ({ isActive, onClick, color, hoverColor }) => {
  const colorClass = color === "dark" ? "dark" : "light";
  const hoverColorClass =
    hoverColor === "hover-dark" ? "hover-dark" : "hover-light";
  const activeClass = isActive ? "active" : "";

  return (
    <button onClick={onClick} className="button-active-component-container">
      <div
        className={`button-active-component ${colorClass} ${hoverColorClass}`}
      >
        <div
          className={`button-active-horizontal ${colorClass} ${hoverColorClass}`}
        ></div>
        <div
          className={`button-active-vertical ${colorClass} ${hoverColorClass} ${activeClass}`}
        ></div>
      </div>
    </button>
  );
};
