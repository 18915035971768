import { useState } from "react";

const useSwipe = (input) => {
  // const minSwipeDistance = window.innerWidth * 0.5;

  // mobile

  // const [touchStart, setTouchStart] = useState(0);
  // const [touchEnd, setTouchEnd] = useState(0);

  // const onTouchStart = (e) => {
  //   setTouchEnd(0); // otherwise the swipe is fired even with usual touch events
  //   setTouchStart(e.targetTouches[0].clientX);
  // };

  // const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  // const onTouchEnd = () => {
  //   if (!touchStart || !touchEnd) return;

  //   const distance = touchStart - touchEnd;

  //   if (Math.abs(distance) < minSwipeDistance) return;

  //   if (distance > 0) {
  //     input.onSwipedLeft();
  //   } else {
  //     input.onSwipedRight();
  //   }
  // };

  // desktop

  const [mouseStart, setMouseStart] = useState(0);
  const [mouseEnd, setMouseEnd] = useState(0);
  const [isDragging, setIsDragging] = useState(false);

  const onMouseDown = (e) => {
    setIsDragging(true);
    setMouseEnd(0);
    setMouseStart(e.clientX);
  };

  const onMouseMove = (e) => {
    if (isDragging) {
      setMouseEnd(e.clientX);
    }
  };

  const onMouseUp = () => {
    if (!isDragging) return;
    setIsDragging(false);

    if (!mouseStart || !mouseEnd) return;
    const distance = mouseStart - mouseEnd;

    const minSwipeDistance = 50;

    if (Math.abs(distance) < minSwipeDistance) return;

    if (distance > 0) {
      input.onSwipedLeft();
    } else {
      input.onSwipedRight();
    }
  };

  return {
    // mobile
    // onTouchStart,
    // onTouchMove,
    // onTouchEnd,
    // desktop
    onMouseDown,
    onMouseMove,
    onMouseUp,
  };
};

export default useSwipe;
