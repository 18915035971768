import { forwardRef, useContext } from "react";
import "./templateLinks.scss";
import { StoreContext } from "../../store/store";
import { ButtonPlus } from "../../components/ButtonPlus/ButtonPlus";
import useIsMobile from "../../utils/hooks/useIsMobile";
import { useLocation } from "react-router-dom";

export const TemplateLinks = forwardRef((props, ref) => {
  const { section } = props;
  const { listActivity } = section;
  const { lang } = useContext(StoreContext);
  const location = useLocation();

  const isMobile = useIsMobile();

  const filteredListActivity = listActivity.data.filter((item) => {
    return !location.pathname.includes(item[lang].button.url.data);
  });

  return (
    <section className="template-links-section">
      <div className="template-links-content">
        {filteredListActivity.map((item, index) => {
          const { title, button = {} } = item[lang];

          return (
            <div key={index} className="template-links-item">
              <ButtonPlus
                to={button.url?.data}
                color={isMobile ? "" : "dark"}
              />
              <span className="template-links-item-label">{title.data}</span>
            </div>
          );
        })}
      </div>
    </section>
  );
});
