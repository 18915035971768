export const Burger = (props) => {
  const { isMenuOpen, toggleMenu } = props;

  const burgerLineClass = `burger-line ${isMenuOpen ? "open" : ""}`;

  return (
    <div className="burger-component" onClick={toggleMenu}>
      <div className={burgerLineClass}></div>
      <div className={burgerLineClass}></div>
      <div className={burgerLineClass}></div>
    </div>
  );
};
