import { API } from "./config";
import axios from "axios";

export const getPageData = async (path) => {
  path = path.split("?")[0];
  let pathTab = path.substring(1).split("/");
  pathTab.shift();
  const pathname = pathTab.join("/");

  return await axios.get(`${API.url}${API.base}/page/${pathname || "home"}`);
};

export const getMenusData = async () => {
  return await axios.get(`${API.url}${API.base}/header`);
};

export const verifyCAPTCHA = async (token) => {
  return await axios.post(`${API.url}${API.base}/captcha`, {
    token,
  });
};

export const sendContact = async (data) => {
  return await axios.post(`${API.url}${API.base}/send`, data);
};
