import { forwardRef } from "react";
import "./podcastActivity.scss";
import { ButtonPlus } from "../../components/ButtonPlus/ButtonPlus";

export const PodcastActivity = forwardRef((props, ref) => {
  const { section } = props;
  const { button, contenu, media, title } = section;

  return (
    <div className="podcast-activity-section">
      <div className="podcast-activity-title">{title.data}</div>
      <div className="podcast-activity-media-container">
        <img src={media.data.path} alt={media.data.alt} draggable="false" />
      </div>
      <div className="podcast-activity-content-container">
        <div
          className="podcast-activity-content"
          dangerouslySetInnerHTML={{ __html: contenu.data }}
        />
        {button.url.data && (
          <ButtonPlus color="dark" to={button.url.data} external />
        )}
      </div>
    </div>
  );
});
