import React, { useEffect, useContext } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import "./app.scss";
import { StoreContext } from "./store/store";
import { getMenusData } from "./store/index.service";

// COMPONENTS
import { Header } from "./components/Header/Header";
import { Footer } from "./components/Footer/Footer";
import { SectionManager } from "./components/SectionManager/SectionManager";

export const App = () => {
  const { pageData, lang, setMenusData } = useContext(StoreContext);

  const navigate = useNavigate();

  useEffect(() => {
    if ("scrollRestoration" in window.history)
      window.history.scrollRestoration = "manual";

    //locale
    if (!localStorage.getItem("currentLanguage")) {
      if (navigator.language.toLocaleLowerCase().slice(0, 2) === "en") {
        localStorage.setItem("currentLanguage", "en");
      } else {
        localStorage.setItem("currentLanguage", "fr");
      }
    }

    (async () => {
      try {
        const res = await getMenusData();
        setMenusData(res.data);
      } catch (err) {
        console.error(err);
      }
    })();

    if (window.location.pathname === "/") {
      navigate(`/${lang}`);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("currentLanguage", lang);
  }, [lang]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pageData]);

  return (
    <div id="App">
      <Header />

      <Routes>
        <Route path="/:locale/*" element={<SectionManager />} />
      </Routes>

      <Footer />
    </div>
  );
};
