import { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { StoreContext } from "../../store/store";

export const Lang = (props) => {
  const { listLang = [] } = props;

  const { pathname, hash } = useLocation();

  const { setLang, lang: currentLang } = useContext(StoreContext);

  const generateLangLink = () => {
    return listLang.map((el, i) => {
      const path = `${pathname.slice(4, pathname.length)}${hash}`;

      return (
        <Link
          key={i}
          to={`/${el}/${path}`}
          className={`header-lang-link ${currentLang === el ? "active" : ""}`}
          onClick={() => setLang(el)}
        >
          {el}
        </Link>
      );
    });
  };

  return <div className="header-lang">{generateLangLink()}</div>;
};
