import { forwardRef } from "react";
import "./outOfTalentActivity.scss";

export const OutOfTalentActivity = forwardRef((props, ref) => {
  const { section } = props;
  const { button, title, media, contenu } = section;

  return (
    <div className="out-of-talent-activity-section">
      <div className="out-of-talent-activity-media-container">
        <img
          src={media.data.path}
          alt={media.data.alt}
          className="out-of-talent-activity-media"
          draggable="false"
        />
      </div>
      <div className="out-of-talent-activity-title">{title.data}</div>

      <div
        className="out-of-talent-activity-content"
        dangerouslySetInnerHTML={{ __html: contenu.data }}
      />
    </div>
  );
});
