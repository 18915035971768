import "./sound.scss";

export const Sound = (props) => {
  const { videoRef, index } = props;

  const toggleSound = () => {
    if (videoRef && videoRef.current) {
      if (Array.isArray(videoRef.current)) {
        videoRef.current[index].muted = !videoRef.current[index].muted;
      } else {
        videoRef.current.muted = !videoRef.current.muted;
      }
    }
  };

  return (
    <div className="sound-component" onClick={toggleSound}>
      <img
        src="/icon/sound.svg"
        alt="icone son"
        className="sound-icon"
        draggable="false"
      />
    </div>
  );
};
