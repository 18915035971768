import "./itemTable.scss";

export const ItemTable = (props) => {
  const { measure, content } = props;

  return (
    <div className="item-table-component">
      <span className="item-table-measure">{measure}</span>
      <div
        className="item-table-content"
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </div>
  );
};
