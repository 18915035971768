import { forwardRef, useContext, useEffect, useRef, useState } from "react";
import "./worldMap.scss";
import { StoreContext } from "../../store/store";
import useSwipe from "../../utils/hooks/useSwipe";

const contentWorldMap = {
  label: "We are a collective of 2,500 creatives around the world",
  maps: [
    {
      src: "/img/AMERICA.svg",
      nameEn: "USA",
      nameFr: "USA",
      key: "america",
    },
    {
      src: "/img/EUROPE.svg",
      nameEn: "Europe",
      nameFr: "Europe",
      key: "europe",
    },
    {
      src: "/img/AFRICA.png",
      nameEn: "Africa",
      nameFr: "Afrique",
      key: "africa",
    },
  ],
};

export const WorldMap = forwardRef((props, ref) => {
  const { section } = props;
  const { listCity, title } = section;
  const { lang } = useContext(StoreContext);

  const [hoveredCity, setHoveredCity] = useState(null);

  const [currentMapIndex, setCurrentMapIndex] = useState(1);
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      setCurrentMapIndex(1);
    }
  }, [containerRef]);

  // Swiper

  const [indexChangeSource, setIndexChangeSource] = useState(null);
  const [mounted, setMounted] = useState(false);

  const swipeHandlers = useSwipe({
    onSwipedLeft: () => handleNextMap(),
    onSwipedRight: () => handlePrevMap(),
  });

  const handlePrevMap = () => {
    setIndexChangeSource("click");
    setCurrentMapIndex((prevIndex) => (prevIndex === 0 ? 2 : prevIndex - 1));
  };

  const handleNextMap = () => {
    setIndexChangeSource("click");
    setCurrentMapIndex((prevIndex) => (prevIndex === 2 ? 0 : prevIndex + 1));
  };

  useEffect(() => {
    if (containerRef.current && (indexChangeSource === "click" || !mounted)) {
      const containerWidth = containerRef.current.getBoundingClientRect().width;
      const totalWidth = containerRef.current.scrollWidth;
      const mapCount = contentWorldMap.maps.length;
      const mapWidth = totalWidth / mapCount;

      const scrollPosition =
        currentMapIndex * mapWidth - (containerWidth - mapWidth) / 2;

      containerRef.current.scrollTo({
        left: scrollPosition,
        behavior: "auto",
      });

      setTimeout(() => {
        setIndexChangeSource(null);
      }, 500);

      setMounted(true);
    }
  }, [currentMapIndex, indexChangeSource, mounted]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const handleScroll = () => {
        if (indexChangeSource !== "click") {
          const containerWidth = container.getBoundingClientRect().width;
          const scrollPosition = container.scrollLeft;
          const newIndex = Math.round(scrollPosition / containerWidth);

          if (newIndex !== currentMapIndex) {
            setCurrentMapIndex(newIndex);
          }
        }
      };

      container.addEventListener("scroll", handleScroll);
      return () => container.removeEventListener("scroll", handleScroll);
    }
  }, [currentMapIndex, indexChangeSource]);

  return (
    <section className="world-map-section">
      <span className="world-map-label">{title.data}</span>

      <div className="world-map-navigation">
        <div
          className="world-map-navigation-left-scroll"
          onClick={handlePrevMap}
        >
          <img
            src="/icon/chevron-left.svg"
            alt="chevron-left"
            draggable="false"
          />
        </div>

        <div
          {...swipeHandlers}
          ref={containerRef}
          className="world-map-content-container"
        >
          {contentWorldMap.maps.map((map, index) => (
            <div className="world-map-content" key={index}>
              <img
                src={map.src}
                alt={lang === "en" ? map.nameEn : map.nameFr}
                key={index}
                draggable="false"
                className="world-map-content-image"
              />
              {index === 1 && hoveredCity && hoveredCity.city === "Paris" && (
                <Paris />
              )}
              {listCity.data
                .filter(
                  (city) =>
                    city[lang].continent.data.toLowerCase() ===
                    map.key.toLowerCase()
                )
                .map((city, cityIndex) => (
                  <div
                    key={cityIndex}
                    className={`${
                      city[lang].city.data === "Paris"
                        ? "world-map-content-point-large"
                        : "world-map-content-point"
                    }`}
                    style={{
                      left: city[lang].positionLeft.data,
                      top: city[lang].positionTop.data,
                    }}
                    onMouseEnter={() =>
                      setHoveredCity({
                        city: city[lang].city.data,
                        continent: city[lang].continent.data,
                        country: city[lang].country.data,
                      })
                    }
                    onMouseLeave={() => setHoveredCity(null)}
                  ></div>
                ))}
            </div>
          ))}
        </div>

        <div
          className="world-map-navigation-right-scroll"
          onClick={handleNextMap}
        >
          <img
            src="/icon/chevron-right.svg"
            alt="chevron-right"
            draggable="false"
          />
        </div>
      </div>

      <div className="world-map-name-container">
        {contentWorldMap.maps.map((map, index) => (
          <div key={index} className="world-map-name-content">
            <div className="world-map-name-content-item">
              {hoveredCity && hoveredCity.city !== "Paris" && (
                <div className="world-map-name-content-item-wrapper">
                  <span className="world-map-name-content-item-wrapper-country">
                    {hoveredCity &&
                    hoveredCity.continent.toLowerCase() ===
                      map.key.toLowerCase()
                      ? hoveredCity.country
                      : ""}
                  </span>
                  <span className="world-map-name-content-item-wrapper-city">
                    {hoveredCity &&
                    hoveredCity.continent.toLowerCase() ===
                      map.key.toLowerCase()
                      ? hoveredCity.city
                      : ""}
                  </span>
                </div>
              )}

              <span className="world-map-name-content-item-name">
                {lang === "en" ? map.nameEn : map.nameFr}
              </span>
            </div>
          </div>
        ))}
      </div>

      <div className="world-map-bullets">
        {contentWorldMap.maps.map((_, index) => (
          <div
            key={index}
            className={`world-map-bullet ${
              index === currentMapIndex ? "active" : ""
            }`}
            onClick={() => {
              setIndexChangeSource("click");
              setCurrentMapIndex(index);
            }}
          />
        ))}
      </div>
    </section>
  );
});

const parisContent = {
  title: "Paris",
  subTitle: "Mediawan group headquarters",
  description:
    "Leading independent European audiovisual content studio over 80 independent production companies, an international distribution arm and 22 streaming channels and services",
};

const Paris = () => {
  return (
    <div className="paris-container">
      <span className="paris-container-title">{parisContent.title}</span>
      <span className="paris-container-sub-title">{parisContent.subTitle}</span>
      <p className="paris-container-description">{parisContent.description}</p>
    </div>
  );
};
