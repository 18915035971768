import { forwardRef, useContext, useEffect, useState } from "react";
import "./picture.scss";
import { StoreContext } from "../../store/store";

export const Picture = forwardRef((props, ref) => {
  const { section } = props;
  const { listSliderProduction } = section;
  const { lang } = useContext(StoreContext);

  const content = listSliderProduction.data?.[0]?.[lang] ?? {};

  const [isDistributionNews, setIsDistributionNews] = useState(false);

  useEffect(() => {
    setIsDistributionNews(document.querySelector(".distribution-news-section"));
  }, []);

  return (
    content && (
      <section className="picture-section">
        <div className="picture-container">
          <img
            src={content.media.data.path}
            alt={content.media.data.alt}
            className="picture-content-image"
            draggable="false"
          />

          {content.title.data && !isDistributionNews && (
            <span className="picture-text picture-text-left">
              {content.title.data}
            </span>
          )}

          {content.from.data && !isDistributionNews && (
            <span className="picture-text picture-text-right">
              {content.from.data}
            </span>
          )}

          {!isDistributionNews && (
            <div className="picture-background-gradient top" />
          )}
          <div className="picture-background-gradient bottom" />
        </div>
      </section>
    )
  );
});
