import { forwardRef, useEffect, useState } from "react";
import "./ourStory.scss";
import { ButtonPlus } from "../../components/ButtonPlus/ButtonPlus";
import useIsMobile from "../../utils/hooks/useIsMobile";

export const OurStory = forwardRef((props, ref) => {
  const { section } = props;
  const { content, title, button } = section;

  const isMobile = useIsMobile();
  const [seeMore, setSeeMore] = useState(false);

  const maxLength = 1000;

  const shortContent =
    content.data.length > maxLength
      ? content.data.substring(0, maxLength) + "..."
      : content.data;

  const handleSeeMore = () => {
    setSeeMore(true);
  };

  useEffect(() => {
    if (isMobile) {
      setSeeMore(false);
    } else {
      setSeeMore(true);
    }
  }, [isMobile]);

  return (
    <section className="our-story-section">
      <span className="our-story-label">{title.data}</span>

      <div className="our-story-description-container">
        <div
          className="our-story-description-content"
          dangerouslySetInnerHTML={{
            __html: seeMore ? content.data : shortContent,
          }}
        ></div>
      </div>

      <div
        className={`our-story-see-more ${seeMore ? "used" : ""}`}
        onClick={handleSeeMore}
      >
        {button.title.data}
      </div>

      <ButtonPlus to={button.url.data} hoverColor="hover-dark" />
    </section>
  );
});
