import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import "./pagination.scss";
import { StoreContext } from "../../store/store";

export const Pagination = (props) => {
  const { array, newsPerPage, onPageChange } = props;
  const { lang } = useContext(StoreContext);

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setCurrentPage(1);
  }, [lang]);

  const totalPages = useMemo(
    () => Math.ceil(array.length / newsPerPage),
    [array, newsPerPage]
  );

  const handlePageChange = useCallback(
    (pageNumber) => {
      const indexOfLastNews = pageNumber * newsPerPage;
      const indexOfFirstNews = indexOfLastNews - newsPerPage;
      const newArrayToShow = array.slice(indexOfFirstNews, indexOfLastNews);

      setCurrentPage(pageNumber);
      onPageChange(newArrayToShow);
    },
    [array, newsPerPage, onPageChange]
  );

  if (array.length <= 6) {
    return null;
  }

  return (
    <div className="latest-news-pagination">
      {Array.from({ length: totalPages }, (_, index) => (
        <div
          key={index}
          onClick={() => handlePageChange(index + 1)}
          className={`pagination-button ${
            currentPage === index + 1 ? "active" : ""
          }`}
        >
          {index + 1}
        </div>
      ))}
    </div>
  );
};
