import { forwardRef, useEffect, useState } from "react";
import "./text.scss";
import { ButtonDown } from "../../components/ButtonDown/ButtonDown";
import useIsMobile from "../../utils/hooks/useIsMobile";

export const Text = forwardRef((props, ref) => {
  const { section } = props;
  const { contenu, contenu2, title } = section;
  const isMobile = useIsMobile();

  const [isDistributionNews, setIsDistributionNews] = useState(false);

  useEffect(() => {
    setIsDistributionNews(document.querySelector(".distribution-news-section"));
  }, []);

  const handleClick = () => {
    if (isDistributionNews) {
      const yOffset = 92;

      window.scrollTo({
        top: isDistributionNews.offsetTop - yOffset,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  const subtitle =
    isMobile && isDistributionNews
      ? contenu.data.replace(/^(.*)&(.*)$/s, "<p>$1</p><p>&$2</p>")
      : contenu.data;

  return (
    <section className="text-section">
      <div className="text-section-content">
        <span className="text-section-title">{title.data}</span>
        <div
          className="text-section-subtitle"
          dangerouslySetInnerHTML={{
            __html: subtitle,
          }}
        />
        <div
          className="text-section-description"
          dangerouslySetInnerHTML={{
            __html: contenu2.data,
          }}
        />

        {isDistributionNews && (
          <div className="text-section-button">
            <ButtonDown color={isMobile ? "" : "dark"} onClick={handleClick} />
          </div>
        )}
      </div>
    </section>
  );
});
