import { forwardRef, useContext } from "react";
import "./ourTalentActivity.scss";
import { StoreContext } from "../../store/store";
import { Link } from "react-router-dom";

export const OurTalentActivity = forwardRef((props, ref) => {
  const { section } = props;
  const { button, title, listOurTalent } = section;

  const { lang } = useContext(StoreContext);

  return (
    <div className="our-talent-activity-section">
      <div className="our-talent-activity-title">{title.data}</div>

      <div className="our-talent-activity-list">
        {listOurTalent.data.map((item, index) => {
          const { media } = item[lang];

          return (
            <div className="our-talent-activity-item" key={index}>
              <img
                src={media.data.path}
                alt={media.data.alt}
                className="our-talent-activity-item-media"
                draggable="false"
              />
            </div>
          );
        })}
      </div>

      {button.url.data && (
        <Link
          to={button.url.data}
          target="_blank"
          className="our-talent-activity-button"
        >
          {button.title.data}
        </Link>
      )}
    </div>
  );
});
