import { forwardRef } from "react";
import "./legalMention.scss";

export const LegalMention = forwardRef((props, ref) => {
  const { section } = props;
  const { title = {}, contenu = {} } = section;

  return (
    <section className="legal-mention-section">
      <span className="legal-mention-title">{title.data}</span>
      <p
        className="legal-mention-content"
        dangerouslySetInnerHTML={{
          __html: contenu.data,
        }}
      ></p>
    </section>
  );
});
