import { useState, createContext } from "react";

export const StoreContext = createContext();

export const StoreProvider = ({ children }) => {
  const [lang, setLang] = useState(
    localStorage.getItem("currentLanguage") === "fr" ? "fr" : "en"
  );
  const [pageData, setPageData] = useState();
  const [menusData, setMenusData] = useState();

  return (
    <StoreContext.Provider
      value={{
        lang,
        setLang,
        pageData,
        setPageData,
        menusData,
        setMenusData,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
