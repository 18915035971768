import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import "./dataTable.scss";
import { ItemTable } from "../../components/ItemTable/ItemTable";
import { StoreContext } from "../../store/store";
import useIsMobile from "../../utils/hooks/useIsMobile";
import useThrottle from "../../utils/hooks/useThrottle";
import useSwipe from "../../utils/hooks/useSwipe";

export const DataTable = forwardRef((props, ref) => {
  const { section } = props;
  const { numberItem, title } = section;
  const { lang } = useContext(StoreContext);

  const isMobile = useIsMobile();
  const [currentIndex, setCurrentIndex] = useState(0);
  const containerRef = useRef(null);

  const numberOfBullets = numberItem.data.length / 3;

  // Swiper

  const [indexChangeSource, setIndexChangeSource] = useState(null);

  const swipeHandlers = useSwipe({
    onSwipedLeft: () => handleNext(),
    onSwipedRight: () => handlePrev(),
  });

  const handlePrev = () => {
    setIndexChangeSource("click");
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? numberOfBullets - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setIndexChangeSource("click");
    setCurrentIndex((prevIndex) =>
      prevIndex === numberOfBullets - 1 ? 0 : prevIndex + 1
    );
  };

  useEffect(() => {
    if (indexChangeSource === "click" && containerRef.current) {
      const containerWidth = containerRef.current.getBoundingClientRect().width;
      const scrollPosition = currentIndex * containerWidth;

      containerRef.current.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      });

      setTimeout(() => {
        setIndexChangeSource(null);
      }, 500);
    }
  }, [currentIndex, indexChangeSource]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const handleScroll = () => {
        if (indexChangeSource !== "click") {
          const containerWidth = container.getBoundingClientRect().width;
          const scrollPosition = container.scrollLeft;
          const newIndex = Math.round(scrollPosition / containerWidth);

          if (newIndex !== currentIndex) {
            setCurrentIndex(newIndex);
          }
        }
      };

      container.addEventListener("scroll", handleScroll);
      return () => container.removeEventListener("scroll", handleScroll);
    }
  }, [currentIndex, indexChangeSource, isMobile]);

  // Update height

  const updateHeight = useCallback(() => {
    if (window.innerWidth <= 960) {
      const items = document.querySelectorAll(
        ".data-table-content-mobile-item"
      );
      let maxHeight = 0;
      items.forEach((item) => {
        item.style.height = "auto";
        maxHeight = Math.max(maxHeight, item.offsetHeight);
      });
      items.forEach((item) => {
        item.style.height = `${maxHeight}px`;
      });
    }
  }, []);

  const throttledUpdateHeight = useThrottle(updateHeight, 10);

  useEffect(() => {
    updateHeight();
    window.addEventListener("resize", throttledUpdateHeight);
    return () => window.removeEventListener("resize", throttledUpdateHeight);
  }, [updateHeight, throttledUpdateHeight]);

  return (
    <section className="data-table-section">
      <div className="data-table-title">{title.data}</div>

      {isMobile ? (
        <div className="data-table-content-mobile">
          <div
            className="data-table-content-mobile-container-left-scroll"
            onClick={handlePrev}
          >
            <img
              src="/icon/chevron-left.svg"
              alt="chevron-left"
              draggable="false"
            />
          </div>
          <div
            {...swipeHandlers}
            ref={containerRef}
            className="data-table-content-mobile-wrapper"
          >
            <div className="data-table-content-mobile-container">
              <div className="data-table-content-mobile-item-wrapper">
                {numberItem.data.slice(0, 3).map((item, index) => (
                  <div key={index} className="data-table-content-mobile-item">
                    <ItemTable
                      measure={item[lang].title.data}
                      content={item[lang].contenu.data}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="data-table-content-mobile-container">
              <div className="data-table-content-mobile-item-wrapper">
                {numberItem.data.slice(3).map((item, index) => (
                  <div key={index} className="data-table-content-mobile-item">
                    <ItemTable
                      measure={item[lang].title.data}
                      content={item[lang].contenu.data}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div
            className="data-table-content-mobile-container-right-scroll"
            onClick={handleNext}
          >
            <img
              src="/icon/chevron-right.svg"
              alt="chevron-right"
              draggable="false"
            />
          </div>
        </div>
      ) : (
        <div className="data-table-content">
          {numberItem.data.map((item, index) => (
            <ItemTable
              key={index}
              measure={item[lang].title.data}
              content={item[lang].contenu.data}
            />
          ))}
        </div>
      )}

      <div className="data-table-bullets">
        {Array.from({ length: numberOfBullets }).map((_, index) => (
          <div
            key={index}
            className={`data-table-bullet ${
              index === currentIndex ? "active" : ""
            }`}
            onClick={() => setCurrentIndex(index)}
          />
        ))}
      </div>
    </section>
  );
});
