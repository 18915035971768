import { useState, forwardRef, useContext, useEffect } from "react";
import "./investors.scss";
import { StoreContext } from "../../store/store";
import "swiper/swiper.min.css";
import useThrottle from "../../utils/hooks/useThrottle";
import { ButtonActive } from "../../components/ButtonActive/ButtonActive";

export const Investors = forwardRef((props, ref) => {
  const { section } = props;
  const [slidesPerView, setSlidesPerView] = useState(5);
  const { lang } = useContext(StoreContext);
  const [categoryOpen, setCategoryOpen] = useState({});

  const toggleItem = (index) => {
    setCategoryOpen((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const updateSlidesPerView = () => {
    if (window.innerWidth <= 500) {
      setSlidesPerView(1);
    } else if (window.innerWidth <= 750) {
      setSlidesPerView(2);
    } else if (window.innerWidth <= 1072) {
      setSlidesPerView(3);
    } else {
      setSlidesPerView(5);
    }
  };

  const throttledUpdateSlidesPerView = useThrottle(updateSlidesPerView, 10);

  useEffect(() => {
    updateSlidesPerView();

    window.addEventListener("resize", throttledUpdateSlidesPerView);

    return () => {
      window.removeEventListener("resize", throttledUpdateSlidesPerView);
    };
  }, [throttledUpdateSlidesPerView]);

  function downloadPDF(url) {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const blobUrl = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = blobUrl;
        a.target = "_blank";
        a.download = "nom-du-fichier.pdf";

        a.click();

        window.URL.revokeObjectURL(blobUrl);
      })
      .catch((error) => {
        console.error(
          "Une erreur s'est produite lors du téléchargement du fichier :",
          error
        );
      });
  }

  function redirectPDf(url) {
    const a = document.createElement("a");
    a.href = url;
    a.target = "_blank";

    a.click();
  }

  return (
    <section className={`investors-section`}>
      <div className={`investors-label`}>{section.title.data}</div>

      {section.ListCategoryInvest.data.map((menuItem, index) => (
        <>
          <div className={`investors-menu-item`} key={index}>
            <div className={`investors-menu-item-header`}>
              <div
                className={`investors-menu-item-title`}
                onClick={() => toggleItem(index)}
              >
                {menuItem[lang].title.data}
              </div>
              <ButtonActive
                color="dark"
                isActive={categoryOpen[index]}
                onClick={() => toggleItem(index)}
              />
            </div>
          </div>

          <div
            spaceBetween={0}
            slidesPerView={slidesPerView}
            className={`investors-spawn ${categoryOpen[index] && "active"}`}
          >
            {menuItem[lang].sousCategoryInvest?.data.map(
              (subItem, subIndex) => (
                <div key={subIndex} className="investors-spawn-element">
                  <span className="investors-spawn-element-date">
                    {subItem[lang].date.data}
                  </span>
                  <p className="investors-spawn-element-title">
                    {subItem[lang].title.data}
                  </p>
                  <div className={`investors-spawn-element-icons`}>
                    {subItem[lang] && subItem[lang].media.data?.path && (
                      <>
                        <span
                          className="material-symbols-outlined"
                          onClick={() =>
                            redirectPDf(subItem[lang].media.data.path)
                          }
                        >
                          arrow_forward_ios
                        </span>

                        <span
                          className="material-symbols-outlined"
                          onClick={() =>
                            downloadPDF(subItem[lang].media.data.path)
                          }
                        >
                          download
                        </span>
                      </>
                    )}
                    <button style={{ display: "none" }}>Télécharger</button>
                  </div>
                </div>
              )
            )}
          </div>
        </>
      ))}

      <div className={`investors-header`}>
        <div className={`investors-header-midd`}></div>
      </div>
    </section>
  );
});
