import { forwardRef } from "react";
import "./digitalActivity.scss";
import { Link } from "react-router-dom";

export const DigitalActivity = forwardRef((props, ref) => {
  const { section } = props;
  const { title, button, media, contenu } = section;

  return (
    <div className="digital-activity-section">
      <div className="digital-activity-title">{title.data}</div>
      <div className="digital-activity-wrapper">
        <div className="digital-activity-content-container">
          <div
            className="digital-activity-content"
            dangerouslySetInnerHTML={{
              __html: contenu.data,
            }}
          />
          {button.url.data && (
            <Link
              to={button.url.data}
              target="_blank"
              className="digital-activity-button"
            >
              {button.title.data}
            </Link>
          )}
        </div>

        <div className="digital-activity-image-container">
          <img
            src={media.data.path}
            alt={media.data.alt}
            className="digital-activity-image"
            draggable="false"
          />
        </div>
      </div>
    </div>
  );
});
