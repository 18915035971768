import { forwardRef } from "react";
import "./hero.scss";
import useIsMobile from "../../utils/hooks/useIsMobile";

export const Hero = forwardRef((props, ref) => {
  const { section } = props;
  const { content, media, mediaMobile, title } = section;

  const isMobile = useIsMobile();

  return (
    <section className="hero-section">
      <div className="hero-content">
        <span className="hero-title">{title.data}</span>
        <div
          className="hero-description"
          dangerouslySetInnerHTML={{ __html: content.data }}
        />
      </div>

      <img
        src={isMobile ? mediaMobile.data?.path : media.data?.path}
        alt={isMobile ? mediaMobile.data?.alt : media.data?.alt}
        className="hero-image"
        draggable="false"
      />
    </section>
  );
});
