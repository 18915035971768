import { forwardRef, useContext, useEffect, useRef, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import "./frieze.scss";
import { StoreContext } from "../../store/store";
import useIsMobile from "../../utils/hooks/useIsMobile";

export const Frieze = forwardRef((props, ref) => {
  const { section } = props;
  const { cultureItem, title } = section;
  const { lang } = useContext(StoreContext);

  const controls = useAnimation();
  const sectionRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const isMobile = useIsMobile();

  useEffect(() => {
    if (isMobile) {
      setIsVisible(true);
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        threshold: 0.1,
      }
    );

    const currentRef = sectionRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [isMobile]);

  useEffect(() => {
    if (isVisible) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, isVisible]);

  const itemVariants = {
    hidden: (index) => ({
      opacity: 0,
      x: index % 2 === 0 ? "-100%" : "100%",
    }),
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 1.5,
        ease: "easeInOut",
      },
    },
  };

  const lineVariants = {
    hidden: { opacity: 0, scaleY: 0 },
    visible: {
      opacity: 1,
      scaleY: 1,
      transition: {
        duration: 1.5,
        ease: "easeInOut",
      },
    },
  };

  return (
    <section className="frieze-section" ref={sectionRef}>
      <span className="frieze-title">{title.data}</span>

      <div className="frieze-values">
        {cultureItem.data.map((value, index) => (
          <div className="frieze-value" key={index}>
            <motion.span
              className="frieze-value-label"
              custom={index}
              variants={itemVariants}
              initial="hidden"
              animate={controls}
              transition={{ delay: index * 0.3 }}
            >
              {value[lang].title.data}
            </motion.span>
            <motion.p
              className="frieze-value-description"
              custom={index}
              variants={itemVariants}
              initial="hidden"
              animate={controls}
              transition={{ delay: index * 0.3 + 0.15 }}
              dangerouslySetInnerHTML={{ __html: value[lang].contenu.data }}
            />
            <motion.div
              className="frieze-value-line"
              variants={lineVariants}
              initial={isVisible ? "visible" : "hidden"}
              animate={controls}
              transition={{ delay: index * 0.3 + 0.3 }}
            />
          </div>
        ))}
      </div>
    </section>
  );
});
