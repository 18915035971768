import { forwardRef, useContext, useEffect, useRef, useState } from "react";
import "./pageList.scss";
import { ButtonPlus } from "../../components/ButtonPlus/ButtonPlus";
import { StoreContext } from "../../store/store";
import { Link } from "react-router-dom";
import useSwipe from "../../utils/hooks/useSwipe";

export const PageList = forwardRef((props, ref) => {
  const { section } = props;
  const { category } = section;
  const { lang } = useContext(StoreContext);

  const [isHome, setIsHome] = useState(false);

  useEffect(() => {
    const checkIfHome = () => {
      const path = window.location.pathname;
      return (
        path === "/" ||
        path === "/en" ||
        path === "/fr" ||
        path === "/en/" ||
        path === "/fr/"
      );
    };
    setIsHome(checkIfHome());
  }, []);

  const List = (props) => {
    const { list } = props;

    const containerRef = useRef(null);
    const [currentIndex, setCurrentIndex] = useState(0);

    // Swiper

    const [indexChangeSource, setIndexChangeSource] = useState(null);

    const swipeHandlers = useSwipe({
      onSwipedLeft: () => handleNext(),
      onSwipedRight: () => handlePrev(),
    });

    const handlePrev = () => {
      setIndexChangeSource("click");
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? list.length - 1 : prevIndex - 1
      );
    };

    const handleNext = () => {
      setIndexChangeSource("click");
      setCurrentIndex((prevIndex) =>
        prevIndex === list.length - 1 ? 0 : prevIndex + 1
      );
    };

    useEffect(() => {
      if (indexChangeSource === "click" && containerRef.current) {
        const containerWidth =
          containerRef.current.getBoundingClientRect().width;
        const scrollPosition = currentIndex * containerWidth;

        containerRef.current.scrollTo({
          left: scrollPosition,
          behavior: "smooth",
        });

        setTimeout(() => {
          setIndexChangeSource(null);
        }, 500);
      }
    }, [currentIndex, indexChangeSource]);

    useEffect(() => {
      const container = containerRef.current;
      if (container) {
        const handleScroll = () => {
          if (indexChangeSource !== "click") {
            const containerWidth = container.getBoundingClientRect().width;
            const scrollPosition = container.scrollLeft;
            const newIndex = Math.round(scrollPosition / containerWidth);

            if (newIndex !== currentIndex) {
              setCurrentIndex(newIndex);
            }
          }
        };

        container.addEventListener("scroll", handleScroll);
        return () => container.removeEventListener("scroll", handleScroll);
      }
    }, [currentIndex, indexChangeSource]);

    return (
      <>
        <div className="page-list-content-activities">
          <div
            {...swipeHandlers}
            ref={containerRef}
            className="page-list-content-activities-container"
          >
            {list.map((item, index) => (
              <div key={index} className="page-list-content-activities-item">
                <div
                  className="page-list-content-scroll-arrow left"
                  onClick={handlePrev}
                >
                  <img
                    src="/icon/chevron-left.svg"
                    alt="chevron-left"
                    draggable="false"
                  />
                </div>
                <Card {...item} />
                <div
                  className="page-list-content-scroll-arrow right"
                  onClick={handleNext}
                >
                  <img
                    src="/icon/chevron-right.svg"
                    alt="chevron-right"
                    draggable="false"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="page-list-bullets">
          {list.map((_, index) => (
            <div
              key={index}
              className={`page-list-bullet ${
                index === currentIndex ? "active" : ""
              }`}
              onClick={() => {
                setIndexChangeSource("click");
                setCurrentIndex(index);
              }}
            />
          ))}
        </div>
      </>
    );
  };

  const Card = (props) => {
    const { title, button, media } = props[lang];

    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const dragThreshold = 5;

    const handleClick = (e) => {
      const deltaX = Math.abs(e.clientX - startX);
      if (isDragging && deltaX > dragThreshold) {
        e.preventDefault();
      }
    };

    const handleDragStart = (e) => {
      setStartX(e.clientX);
      setIsDragging(true);
    };

    const handleDragEnd = () => {
      setTimeout(() => {
        setIsDragging(false);
        setStartX(0);
      }, 0);
    };

    return (
      <Link
        to={button.url.data}
        style={{ width: "100%" }}
        onClick={handleClick}
        onMouseDown={handleDragStart}
        onMouseUp={handleDragEnd}
        onMouseLeave={handleDragEnd}
        draggable="false"
      >
        <div className="page-list-content-activities-card">
          <img
            src={media.data.path}
            alt={media.data.alt}
            className="page-list-content-activities-card-image"
            draggable="false"
          />

          <span className="page-list-content-activities-card-title">
            {title.data}
          </span>

          <div className="page-list-content-activities-card-shadow" />
        </div>
      </Link>
    );
  };

  return (
    <section className={`page-list-section ${isHome ? "home" : ""}`}>
      {category.data.map((page, index) => (
        <div className="page-list-content" key={index}>
          <span className={`page-list-title ${isHome ? "home" : ""}`}>
            {page[lang].title.data}
          </span>
          {index !== 0 || isHome ? (
            <>
              <p className={`page-list-description ${isHome ? "home" : ""}`}>
                {page[lang].content.data}
              </p>
              <ButtonPlus
                to={page[lang].button.url.data}
                hoverColor="hover-dark"
              />
            </>
          ) : (
            <List list={page[lang].sousCategorie.data} />
          )}
        </div>
      ))}
    </section>
  );
});
