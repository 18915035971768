import { forwardRef, useContext, useEffect, useRef, useState } from "react";
import "./latestNews.scss";
import { ButtonPlus } from "../../components/ButtonPlus/ButtonPlus";
import { StoreContext } from "../../store/store";
import useIsMobile from "../../utils/hooks/useIsMobile";
import { useLocation } from "react-router-dom";
import { Pagination } from "../../components/Pagination/Pagination";
import useSwipe from "../../utils/hooks/useSwipe";

export const LatestNews = forwardRef((props, ref) => {
  const { section } = props;
  const { title, listNews } = section;
  const { lang } = useContext(StoreContext);

  const containerRef = useRef(null);
  const isMobile = useIsMobile();
  const [currentIndex, setCurrentIndex] = useState(0);

  const location = useLocation();
  const isNewsPage = location.pathname.includes("news");

  // Swiper

  const [indexChangeSource, setIndexChangeSource] = useState(null);

  const swipeHandlers = useSwipe({
    onSwipedLeft: () => handleNext(),
    onSwipedRight: () => handlePrev(),
  });

  const handlePrev = () => {
    setIndexChangeSource("click");
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? listNews.data.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setIndexChangeSource("click");
    setCurrentIndex((prevIndex) =>
      prevIndex === listNews.data.length - 1 ? 0 : prevIndex + 1
    );
  };

  useEffect(() => {
    if (indexChangeSource === "click" && containerRef.current) {
      const containerWidth = containerRef.current.getBoundingClientRect().width;
      const scrollPosition = currentIndex * containerWidth;

      containerRef.current.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      });

      setTimeout(() => {
        setIndexChangeSource(null);
      }, 500);
    }
  }, [currentIndex, indexChangeSource]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const handleScroll = () => {
        if (indexChangeSource !== "click") {
          const containerWidth = container.getBoundingClientRect().width;
          const scrollPosition = container.scrollLeft;
          const newIndex = Math.round(scrollPosition / containerWidth);

          if (newIndex !== currentIndex) {
            setCurrentIndex(newIndex);
          }
        }
      };

      container.addEventListener("scroll", handleScroll);
      return () => container.removeEventListener("scroll", handleScroll);
    }
  }, [currentIndex, indexChangeSource]);

  // Pagination

  const [displayedNews, setDisplayedNews] = useState(
    isMobile ? listNews.data : listNews.data.slice(0, 6)
  );

  const sectionRef = useRef(null);

  useEffect(() => {
    setDisplayedNews(isMobile ? listNews.data : listNews.data.slice(0, 6));
  }, [listNews.data, isMobile]);

  const handlePageChange = (newArray) => {
    setDisplayedNews(newArray);
    sectionRef.current.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  };

 

  

  return (
    <section
      ref={sectionRef}
      className={`latest-news-section ${
        isNewsPage ? "latest-news-section-news" : ""
      }`}
    >
      <span className="latest-news-label">{title.data}</span>

      <div className="latest-news-container">
        <div
          {...swipeHandlers}
          ref={containerRef}
          className="latest-news-wrapper"
        >
          {displayedNews.map((news, index) => {
            const {
              title,
              subTitle,
              btnUrl,
              media,
              content,
              shortContent,
              isBlack,
            } = news[lang] ?? {};

            const isBlackContent = isBlack.data;

            return (
              <div className="latest-news-content-container" key={index}>
                <div
                  className={`latest-news-content-container-wrapper ${
                    isBlackContent ? "order-first" : "order-last"
                  }`}
                >
                  <div
                    className={`latest-news-content ${
                      isBlackContent ? "bg-grey" : "bg-beige"
                    }`}
                  >
                    <div
                      className="latest-news-content-container-left-scroll"
                      onClick={() => handlePrev()}
                    >
                      <img
                        src="/icon/chevron-left.svg"
                        alt="chevron-left"
                        draggable="false"
                      />
                    </div>
                    <div className="latest-news-content-container-wrapper-content">
                      <div className="latest-news-content-upper">
                        <span
                          className={`latest-news-content-title ${
                            isBlackContent ? "light" : ""
                          }`}
                        >
                          {title.data}
                        </span>
                        <span
                          className={`latest-news-content-subtitle ${
                            isBlackContent ? "light" : ""
                          }`}
                        >
                          {subTitle.data}
                        </span>
                      </div>

                      <div
                        className={`latest-news-content-description ${
                          isBlackContent ? "light" : ""
                        }`}
                        dangerouslySetInnerHTML={{
                          __html: shortContent.data,
                        }}
                      />

                      {btnUrl.data ? (
                        <a
                          className={`latest-news-content-download ${
                            isBlackContent ? "light" : ""
                          }`}
                          href={btnUrl.data}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {lang === "fr" ? "Télécharger" : "Download"}
                        </a>
                      ) : content.data ? (
                        <ButtonPlus
                          color={isBlackContent ? "light" : ""}
                          to={`news/${title.slug}`}
                        />
                      ) : null}
                    </div>
                    <div
                      className="latest-news-content-container-right-scroll"
                      onClick={() => handleNext()}
                    >
                      <img
                        src="/icon/chevron-right.svg"
                        alt="chevron-right"
                        draggable="false"
                      />
                    </div>
                  </div>
                </div>

                <div className="latest-news-content-image-container">
                  <img
                    src={media.data.path}
                    alt={media.data.alt}
                    className={`latest-news-content-image ${
                      isBlackContent ? "order-last" : "order-first"
                    }`}
                    draggable="false"
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {!isMobile && (
        <Pagination
          array={listNews.data}
          newsPerPage={6}
          onPageChange={handlePageChange}
        />
      )}

      {isMobile && (
        <div className="latest-news-bullets">
          {displayedNews.map((_, index) => (
            <div
              key={index}
              className={`latest-news-bullet ${
                index === currentIndex ? "active" : ""
              }`}
              onClick={() => {
                setIndexChangeSource("click");
                setCurrentIndex(index);
              }}
            />
          ))}
        </div>
      )}
    </section>
  );
});
