import { useContext } from "react";
import "./buttonPlus.scss";
import { Link } from "react-router-dom";
import { StoreContext } from "../../store/store";

export const ButtonPlus = (props) => {
  const { to, color, hoverColor, external } = props;
  const { lang } = useContext(StoreContext);

  const colorClass = color === "dark" ? "dark" : "light";
  const hoverColorClass =
    hoverColor === "hover-dark" ? "hover-dark" : "hover-light";

  return (
    <Link
      to={external ? to : `/${lang}/${to}`}
      className="button-plus-component-container"
      target={external ? "_blank" : "_self"}
    >
      <div className={`button-plus-component ${colorClass} ${hoverColorClass}`}>
        <div
          className={`button-plus-horizontal ${colorClass} ${hoverColorClass}`}
        ></div>
        <div
          className={`button-plus-vertical ${colorClass} ${hoverColorClass}`}
        ></div>
      </div>
    </Link>
  );
};
