import { forwardRef } from "react";
import "./lifeExpActivity.scss";
import { Link } from "react-router-dom";

export const LifeExpActivity = forwardRef((props, ref) => {
  const { section, bgColor } = props;
  const { title, media, contenu, button } = section;

  const isTextLight = bgColor === "black";

  return (
    <div
      className="life-exp-activity-section"
      style={{
        background: `url(${media.data.path})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="life-exp-activity-content-wrapper">
        <div
          className={`life-exp-activity-title ${isTextLight ? "light" : ""}`}
        >
          {title.data}
        </div>

        <div className="life-exp-activity-wrapper">
          <div
            className={`life-exp-activity-content ${
              isTextLight ? "light" : ""
            }`}
            dangerouslySetInnerHTML={{
              __html: contenu.data,
            }}
          />

          {button.url.data && (
            <Link
              to={button.url.data}
              target="_blank"
              className="life-exp-activity-button"
            >
              {button.title.data}
            </Link>
          )}
        </div>
      </div>

      <div className="life-exp-activity-shadow" />
    </div>
  );
});
